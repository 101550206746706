.header-custom {
  font-size: 16px;
  padding-right: 5px;
}

.box-size{
  height: 100%;
}

.company-box a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.a-custom{
  color: #000;
}

a:hover {
  color: #4044ee;
  text-decoration: none;
}

.link{
  color: #4044ee;
}

.logo{
  width: 3rem;
}

.company-logo {
  width: 9rem; /* Set the desired width for the company logos */
}
